.singleChallenge{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0rem 6rem;
    padding: 5rem;
    @media screen and (max-width: 1400px) {
        padding: 2rem;
        margin: 3rem;
    }
    @media screen and (max-width: 700px) {
       padding: 1rem;
       margin: 0rem;
    }
}

.preview{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: white;
    background-color: var(--color-dark-gray);
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    @media screen and (max-width: 700px) {
       flex-direction: column;
    }
    img{
        width: 40%;
        margin-right: 4rem;
        @media screen and (max-width: 1050px) {
            margin-right: 2rem;
            width: 45%;
        }
        @media screen and (max-width: 700px) {
            width: 100%;
            margin-right: 0rem;
            margin-bottom: 1rem;
        }
    }
}

.previewText{
    width: 40%;
    line-height: 1.8rem;
    @media screen and (max-width: 1400px) {
            width: 50%;
            padding: 1rem;
    }
    @media screen and (max-width: 1050px) {
            width: 55%;
    }
    @media screen and (max-width: 700px) {
       width: 100%;
    }
    h2{
        font-size: 3rem;
        margin-bottom: 2rem;
        @media screen and (max-width: 1050px) {
            font-size: 2rem;
        }
        @media screen and (max-width: 700px) {
            font-size: 1.5rem;
        }
    }
    p{
        font-size: 1.4rem;
        margin-bottom: 2rem;
        @media screen and (max-width: 1050px) {
            font-size: 1.3rem;   
        }
        @media screen and (max-width: 700px) {
            font-size: 1rem;
        }
    }
}

.levelInfoWrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    p{
        margin-right: 2rem;
    }
    .difficulty{
        padding: 0rem 1.5rem;
        font-size: 18px;
        border-radius:3px;
        @media screen and (max-width: 700px) {
            font-size: 1rem;
        }
    }
}

.Beginner{
    border: 2px solid #81AFDD;
    color: #81AFDD;
}

.Intermediate{
    border: 2px solid #f4f49e;
    color: #f4f49e;
}

.Advanced{
    border: 2px solid #fa9018;
    color: #fa9018;
}

.details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 700px) {
    flex-direction: column;
    }
    code{
        background-color: gray;
        font-family: monospace;
    }
}

.assets{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem;
    background-color: var(--color-dark-gray);
    width: 40%;
    border-radius: 0.5rem;
    color: white;
    font-size: 1.3rem;
    @media screen and (max-width: 700px) {
       width: 100%;
       padding: 1rem;
    }
    ul{
        margin: 1.5rem 1rem;
        @media screen and (max-width: 700px) {
            font-size: 1rem;
        }
    }
    ul li{
        list-style-type: "\2713";
    }
    a{
        padding: 0.5rem 1rem;
        color: white;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        background: var(--color-button);
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        margin-bottom: 1rem;
        &:hover{
            background: var(--color-button-hover);
        }
    }
    .premiumAssets{
        background: var(--color-subtext);
        &:hover{
            background: var(--color-subtext-hover);
        }
    }
}



.brief{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem;
    background-color: var(--color-dark-gray);
    width: 55%;
    border-radius: 0.5rem;
    color: white;
    line-height: 1.4em;
    font-size: 1.3rem;
    @media screen and (max-width: 700px) {
       width: 100%;
       margin-top: 1rem;
       padding: 1rem;
       font-size: 1rem;
    }
    h3{
        margin-bottom: 1rem;
    }
    ul{
        margin: 1.5rem 1rem;
        @media screen and (max-width: 700px) {
            font-size: 1rem;
        }
    }
}

