.challenges{
    margin: 8rem;
    position: relative;
    @media screen and (max-width: 1400px) {
        margin: 8rem 4rem;
    }
    @media screen and (max-width: 700px) {
        margin: 1rem;
    }
    h2{
        text-align: center;
        color: white;
        text-transform: uppercase;
        margin-top: 8rem;
        z-index: 10;
        margin-bottom: 2rem;
    }
}

.challengesList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
}

.shape{
    width: 100%;
    height: 300px;
    background: linear-gradient(var(--color-button-hover), var(--color-bg));
    position: absolute;
    z-index: -1;
    top: -2rem;
    border-radius: 10px;
    // -webkit-clip-path: polygon(9% 0, 100% 0%, 91% 100%, 0% 100%);
    // clip-path: polygon(9% 0, 100% 0%, 91% 100%, 0% 100%);
}