.hero{
    color: white;
    margin: 6rem 15rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1400px) {
        margin: 2rem 6rem;
    }
    @media screen and (max-width: 1050px) {
        margin: 1rem 4rem;
}
    @media screen and (max-width: 700px) {
        flex-direction: column;
        width: 90%;
        margin: 1rem
    }
}

.hero-left{
    width: 40%;
    margin-right: 6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media screen and (max-width: 1400px) {
        margin-right: 4rem;
    }
    @media screen and (max-width: 700px) {
        margin-right: 0;
        width: 100%;
        padding: 1rem;
    }
    h1{
        margin-bottom: 2rem;
        font-size: 3.3rem;
        @media screen and (max-width: 1400px) {
            font-size: 2.5rem;
        }
        @media screen and (max-width: 1050px) {
            font-size: 1.5rem;
        }
        @media screen and (max-width: 700px) {
            font-size: 2rem;
        }
    }
    p{
        margin-bottom: 2rem;
        font-size: 1.5rem;
        line-height: 1.5em;
        @media screen and (max-width: 1400px) {
            font-size: 1.3rem;
        }
        @media screen and (max-width: 1050px) {
            font-size: 1rem;
    }
        @media screen and (max-width: 700px) {
            font-size: 1rem;
        }
    }
    button{
        padding: 0.5rem 1rem;
        color: white;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        background: var(--color-button);
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        &:hover{
            background: var(--color-button-hover);
        }
    }
}

.hero-right{
    width: 50%;
    @media screen and (max-width: 700px) {
        width: 100%;
        margin: 1rem;
    }
    img{
        width: 100%;
    }
}

.blob { 
    width: 782px;
    height: 514px;
    background-size: cover;
    background-repeat: no-repeat;
    // background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width=%22682.487%22 height=%22514.15%22 viewBox=%22108.515 260.901 782.487 514.15%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(180 .5 .5)%22%3E%3Cstop offset=%220%25%22 stop-color=%22%232f2f2f%22%2F%3E%3Cstop offset=%22100%25%22 stop-color=%22%23031a5f%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M786 615Q575 730 336.5 761T109 508q11-284 236.5-255.5t438.5 138Q997 500 786 615Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M786 615Q575 730 336.5 761T109 508q11-284 236.5-255.5t438.5 138Q997 500 786 615Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    @media screen and (max-width: 700px) {
        background-image: none;
    }
  }
  
  
  
  