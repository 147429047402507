.about{
    color: white;
    text-align: center;
    margin: 5rem 8rem;
    @media screen and (max-width: 1400px) {
        margin: 5rem 4rem;
    }
    @media screen and (max-width: 700px) {
        width: 90%;
        margin: 1rem;
    }
    h2{
        text-transform: uppercase;
        font-size: 1rem;
        @media screen and (max-width: 700px) {
            font-size: 1.5rem;
        }
    }
}

.aboutContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
}

.aboutCard{
    flex: 1;
    width: 25%;
    margin: 3rem;
    @media screen and (max-width: 1400px) {
        margin: 1.5rem;
    }
    @media screen and (max-width: 700px) {
        width: 100%;
        margin: 1rem 0;
    }
    h3{
        margin: 1.5rem 0;
    }
    p{
        line-height: 1.5em;
        a{
            color: var(--color-button)
        }
    }
}

