@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

.gpt3__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
    width: 100%;
}

.gpt3__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo{
    margin-right: 1rem;
    color: var(--color-button);
    h1{
        font-size: 1.5rem;
        font-family: 'Abel', sans-serif;
    }
}

.gpt3__navbar-links_logo img{
    width: 150px;
}

.gpt3__navbar-links_container{
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p, .gpt3__navbar-sign p, .gpt3__navbar-menu_container p{
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
    a {
        position: relative;
      }
      
      a::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 4px;
          border-radius: 4px;
          background-color: var(--color-button);
          bottom: 0;
          left: 0;
          transform-origin: right;
          transform: scaleX(0);
          transition: transform .3s ease-in-out;
        }
      
      a:hover::before {
        transform-origin: left;
        transform: scaleX(1);
      }
}

.gpt3__navbar-sign button, .gpt3__navbar-menu_container button{
    padding: 0.5rem 1rem;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    background: var(--color-button);
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover{
        background: var(--color-button-hover);
    }
}

.gpt3__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}

.gpt3__navbar-menu svg{
    cursor: pointer;
}

.gpt3__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.gpt3__navbar-menu_container p{
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign{
    display: none;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container{
        display: none;
    }
    .gpt3__navbar-menu{
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar{
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar{
        padding: 2rem;
    }

    .gpt3__navbar-sign{
        display: none;
    }

    .gpt3__navbar-menu_container{
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign{
        display: block;
    }
}



