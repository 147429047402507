.challengeCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 25%;
    min-height: 550px;
    margin: 2rem;
    border-radius: 10px;
    background-color: var(--color-dark-gray);
    color: white;
    position: relative;
    transition: all 0.3s ease-in-out;
    padding: 0.5rem;
    line-height: 1.4rem;
    @media screen and (max-width: 1400px) {
        width: 30%;
        margin: 1rem;
    }
    @media screen and (max-width: 1050px) {
        width: 45%;
    }
    @media screen and (max-width: 700px) {
        width: 90%;
        margin: 1rem 0rem;
    }
    &:hover{
        cursor: pointer;
        transform: scale(1.1);
    }
    img{
        width: 100%;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
    .difficulty{
        padding: 0rem 1.5rem;
        font-size: 18px;
        border-radius:3px;
    }
    .Beginner{
        border: 2px solid #81AFDD;
        color: #81AFDD;
    }
    
    .Intermediate{
        border: 2px solid #f4f49e;
        color: #f4f49e;
    }
    
    .Advanced{
        border: 2px solid #fa9018;
        color: #fa9018;
    }
    h3{
        font-size: 1.5rem;
        margin: 1rem;
    }
    p{
        padding: 0.5rem;
        line-height: 1.4rem;
    }
}

.challengeHighlights{
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.premium{
    -webkit-text-fill-color: transparent;
    background: var(--gradient-text);
    -webkit-background-clip: text;
    background-clip: text;
}