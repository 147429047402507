.signup{
    background: var(--gradient-bar);
    margin: 8rem;
    padding: 2rem;
    border-radius: 10px;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1050px) {
        margin: 5rem;
}
    @media screen and (max-width: 700px) {
        margin: 1rem;
        padding: 1rem;
    }
    h2{
        text-align: center;
        margin-bottom: 2rem;
        font-size: 40px;
        z-index: 1;
        @media screen and (max-width: 700px) {
            font-size: 1.5rem;
        }
    }
    p{
        z-index: 1;
        font-size: 24px;
        padding: 1rem;
        text-align: center;
        width: 60%;
        line-height: 1.5em;
        @media screen and (max-width: 1400px) {
            width: 75%;
        }
        @media screen and (max-width: 700px) {
            padding: 0rem;
            font-size: 1rem;
            width: 100%;
            text-align: left;
        }
    }
    a{
        z-index: 1;
    }
    button{
        padding: 0.5rem 1rem;
        color: white;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        background: var(--color-button);
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        &:hover{
            background: var(--color-button-hover);
        }
    }
    img{
        width: 450px;
        position: absolute;
        z-index: 0;
        left: -100px;
        top: -50px;
        padding: 1rem;
        transform: rotate(-45deg);
        // opacity: 0.3;
        @media screen and (max-width: 1400px) {
            width: 350px;
        }
        @media screen and (max-width: 1050px) {
            width: 300px;
        }
        @media screen and (max-width: 700px) {
            width: 100px;
            left: 0px;
            top: 0px;
            opacity: 0.3;
        }
    }
}